/* FONTS */
@import url("https://use.typekit.net/cqt6qou.css");

@font-face {font-family: "Ogg";
  src: url("../fonts/Ogg.woff2");
}

@font-face {font-family: "normalidad-text", sans-serif;
  src: url("https://use.typekit.net/cqt6qou.css"); 
}

/* FONTS */

/* S */
@media only screen and (min-width: 0px) {
  p{font-size: clamp(1.25rem, 0.875rem + 1.0417vw, 1.375rem);}
  span, th{font-size: clamp(1rem, -0.1923rem + 1.9231vw, 1.25rem);}
  h1, .link_poema{font-size: clamp(1.5rem, 1.5rem + 1.0417vw, 1.5rem);}
  .title-map{font-size: 29px}
  .title_nav, h4{font-size: clamp(0.75rem, .625rem + 1.0417vw, 0.5rem);}
  .example-left{font-size: 46px;}
}

@media only screen and (min-width: 576px) {
  p{font-size: clamp(1.25rem, 0.875rem + 1.0417vw, 1.375rem);}
  span, th{font-size: clamp(1rem, -0.1923rem + 1.9231vw, 1.25rem);}
  h1, .link_poema{font-size: clamp(1.5rem, 1.5rem + 1.0417vw, 1.5rem);}
  .title-map {font-size: 29px}
  .title_nav, h4{font-size: clamp(0.75rem, 0.625rem + 1.0417vw, 1rem);}
  .example-left{font-size: 46px;}

}
/* MD */
@media only screen and (min-width: 768px) {
  p{font-size: clamp(1.375rem, 0.9464rem + 0.8929vw, 1.5rem);}
  span {font-size: clamp(1rem, -0.1923rem + 1.9231vw, 1.25rem);}
  .table td, .table th{font-size:clamp(1.5rem, .625rem + .5vw, 1.5rem);}
  h1, .link_poema{font-size: clamp(2rem, 1.3571rem + 1.3393vw, 2.1875rem);}
  .title-map {font-size: 29px}
  .title_nav, h4{font-size: clamp(1.125rem, 0.6964rem + 0.8929vw, 1rem);}
  .example-left{font-size: 70px;}

}
/* LARGE */
@media only screen and (min-width: 992px) {
  p{font-size: clamp(1.5rem, -0.2885rem + 2.8846vw, 1.875rem);}
  span, th{font-size: clamp(1rem, .5rem + 0.5vw, 1.5rem);}
  h1, .link_poema{font-size: clamp(1.875rem, 1.2788rem + 0.9615vw, 2rem);}
  .title-map {font-size: 37px}
  .title_nav, h4{font-size: clamp(1.125rem, 0.6964rem + 0.8929vw, 1rem);}
  .example-left{font-size: 90px;}

}
/* XX-LARGE */
@media only screen and (min-width: 1200px) {
  p{font-size: clamp(1.875rem, 1.125rem + 1vw, 2rem);}
  span, th{font-size: clamp(0.5rem, .625rem + 0.5vw, 1.25rem);}
  h1, .link_poema{font-size: clamp(1.875rem, 1.2788rem + 0.9615vw, 2rem);}
  .title-map {font-size: 37px}
  .title_nav, h4{font-size: clamp(1.05rem, .6964rem + .8929vw, 1rem);}
  .example-left{font-size: 90px;}
}

@media only screen and (min-width: 1600px) {
  h1, .link_poema{font-size: clamp(2rem, -5.5rem + 10vw, 3.25rem);}
  .title-map {font-size: 37px}
  .title_nav, h4{font-size: clamp(1.125rem, 0.6964rem + 0.8929vw, 1.25rem);}
}

/* Animations */
.bg-active{
  background-color: #fff; 
  transition: 0.5s ease-in-out;}

 @media (max-width: 1000px) {

  .content-first {
    width: 100%;
    height: auto!important;
    padding: 0 0 0 30px;
    display: flex;
    flex-direction: column;
}
  .bg-active {
    background-color:#fff} }


.title_nav.light{
  font-weight: 300;
}

.legenda-slide{
  font-weight: 100!important;
}

.background{
  background-color: #c6c4bd;;
  color: #1d1d1d;
}

.border{
  border: 2px solid #1d1d1d!important;
  border-radius: 16px;
}

hr{
  margin-top: 0px!important;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  color: #1d1d1d;
}

/* PRELOADER */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 999999999999;
}

/* AUDIO PLAYER + GRID */
.display-none {
  display: none;
}

.cell-poema{
  max-width: 100vw;
  width: 33.3vw;
  height: 100%;
  max-height: 100vh;
  overflow: visible;
}

.figure{
  padding: 0 25px;
  width: 100vw;
}
.image-galeria{
  border: 2px solid red;
  width: 100vw;
  height: 80vh;
  object-fit: cover;
}

.poema_image img{
  display: block;
  width: 100vw;
  height: 100%;
}
.poema_image{
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100vw;
}

.gridcontainer {
  display: grid;
  justify-content: left;
  height: 100%;
  max-height: 100%;
}

.text-left{
  padding-left: 0px;
}

.text-right{
  padding-right: 0px;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  width: 100%;
  min-height: 1px;
  padding-left: 0px; 
  padding-right: 0px; 
  position: relative;
}

.row_default {
  grid-template-columns: 1fr 0.2fr;
  color: #1d1d1d;
}

.col_default {
  color: #1d1d1d;
  grid-template-columns: 2fr 1fr;
}

p.item_main{
  width: fit-content;
}

@media (max-width:1000px) {
  .section-content-header{
    padding: 12.5px 15px!important;
  }

  .gridcontainer {
    display:block;
    justify-content: left;
    height: 100%;
  }

  .no-mobile{
  display: none;
  }


}


#menu-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 12;
  pointer-events: none;
}

svg{
  fill: #c77945;
}

#menu-toggler {
  z-index: 500;
}

#menu-bg img {
  width: 100%;
}

#menu-contain {
  display: none;
}

#menu-contain.open {
  z-index: 12;
  padding: 2vw;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
}

@media (max-width: 768px) {
  #menu-contain.open {
    flex-direction: column;
}
.accordion-container {
  max-width: 100%!important;
  height: auto;
  position: relative;
}

.hide-map{
  display: none;
}

.hide-mobile{
  display: none;
}

#menu-contain.open {
  z-index: 12;
  min-height: 100vh;
  align-items: stretch;
  padding: 2vw;
  display: flex;
  justify-content: flex-end;
}

.itemsMenu, .itemsLogos {
  flex-direction: column;
  flex: 0!important;
  justify-content: flex-end;
  align-items: stretch;
  padding: 1vw;
  display: flex;
}

.row{
  justify-content: space-between;
}

}


@media (max-width: 1000px) {

  .accordion-container {
    max-width: 100%!important;
    height: auto;
    position: relative;
  }

  #menu-contain.open {
    flex-direction: column;
}
.hide-map{
  display: none;
}

.hide-mobile{
  display: none;
}

.row{
  justify-content: space-between;
}

#menu-contain.open {
  z-index: 12;
  min-height: 100vh;
  align-items: stretch;
  padding: 2vw;
  display: flex;
  justify-content: flex-end;
}

.itemsMenu, .itemsLogos {
  flex-direction: column;
  flex: 0!important;
  justify-content: flex-end;
  align-items: stretch;
  padding: 1vw;
  display: flex;
}

}




.itemsMenu, .itemsLogos {
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  padding: 1vw;
}

.itemsLogos {
  justify-content: flex-end;
  flex-direction: column;
}

.menu-list li {
  overflow: hidden;
}

.menu-list a, .rotas {
  display: block;
  font-size: 40px;
  transition: .5s transform;
  text-transform: capitalize;
}

.menu-list a:hover{
    transform: translateY(-100%) !important;
    text-decoration: none;
}

.menu-list a::after{
    content: attr(title);
    position: absolute;
    top: 100%;
    left: 0;
}

@media (min-width:1000px) {
  .rota-mobile{
    display: none;
  }

  .text-left-dif{
    text-align: left;
  }

  .content_home_audio {
    max-height: 100vh;
    height: 100%;
    margin: 0 0 0 0;
    flex: 1 0;
  }

  .right-sidebar {
    top: 62.5px;
    right: 0;
    position: absolute;
    background-color: #ffffff;
    z-index: 99999999999;
    flex: 0 0 50%;
    margin: 0 0 0 0;
    display: none;
    border-left: 2px solid #0d0d0d;
    height: 100vh;
  }

  .content_home {
    max-height: 100vh;
    height: auto;
    margin: 0 0 0 0;
    flex: 1 0;
}


.cell-poema {
  max-width: 100vw;
  width: 33.3vw;
  height: 100%;
  max-height: 100vh;
  overflow: visible;
}
}

.logos{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 20px;
}

.row {
  flex-wrap: wrap;
  margin-left: 0!important;
  margin-right: 0!important;
  display: flex;}

/* ROLL TEXT */

@media (max-width:1000px) {

  .main_home_page{
    margin: 50px 0 0;
  }

  .rota-mobile{
    display: block;
    padding: 25px;
    font-weight: 500;
  }

  article{
    height: 13vh!important;
    overflow: hidden;
    margin-top:5px!important;
  }
}

.leaflet-top, .leaflet-bottom{
  z-index: 400!important;
}

article {
  max-width: 100%;
  margin-top: 25px;
  position: relative;
  min-height: 15vh;
}

.example-left {
  white-space: nowrap;
  position: absolute;
  text-transform: uppercase;
}

th:hover{
  background-color: #fff;
}

.example-left {
  white-space: nowrap;
  font-family: "Ogg";
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
  color: transparent;
}

/* GRID TEMPLATE */
.section{
  overflow: hidden!important;
}

.page-wrap {
  max-width: 100%;
  min-height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden!important;
}

.main_home {
  flex: 1 0 0%;
  margin: 60px 0 0 0;
  display: flex;
  flex-wrap: wrap;
}

.slide_home{
  margin: 0 0 0 0;
  flex: 0 0;

}

.content_home {
  max-height: 100vh;
  height: 45vh;
  margin: 0 0 0 0;
  flex: 1 0;
}

.right-sidebar {
  flex: 0 0 50%;
  margin: 0 0 0 0;
  display:none;
  border-left: 2px solid #0d0d0d;
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .spacer-dot{
    height:20px!important;
  }

  .sidebar,
  .content_home {
    height: auto;
    flex: 1 0 100%;
    margin: 0 0;
    align-content: space-around;
  }
  .content {
    order: -1;
  }
}

.show {
  display: block;
}

.scrollTop {
  transition: all 0.4s ease-in-out 0s;
}

.grid-two{
  display: grid;
  grid-template-areas: "header" "content" "slideimage" "mapa" "footer";
  grid-template-rows: 1fr auto;
  max-width: 100%;
  overflow: auto;
overflow-x: hidden;}

.main_home_page {
  flex: 1 0 0%;
  margin: 90px 0 0 0;
  display: flex;
  flex-wrap: wrap;
}

.mapa_grid{
  flex: 1 0 0%;
  margin: 25px 0;
  display: flex;
  flex-wrap: wrap;
}

.slideimage{
  margin: 0 0 0 0;
  flex: 0 0;
}

.hide-map{
  display: none!important;
  opacity: 0!important;
}

.fixed-position {
  position: fixed! important;
  overflow: hidden!important;
}

.shadow {
  z-index: 10;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  background: hsla(0,0%,100%,.8);
  box-shadow: 1px 5px 8px 5px rgb(0 71 55 / 3%);
}

.section-content-header{
  top:0px;
  width: 100%;
  height: auto;
  text-transform: uppercase;
  padding: 15px 30px;
  font-weight: 500;
  z-index: 14;
  display: block;
  position: sticky;
}

/* NAV */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .section-content-header {
  padding-top: 60px;
}

.title_nav{
  font-weight: 500;
}

.title_nav_light{
  font-weight: 100;
}

/*a links*/
a:-webkit-any-link, a {
  color: #1d1d1d;
  cursor: pointer;
}

.links_pdf {
  padding: 25px;
  text-align: left;
}

.no-padding{
  padding-top: 0!important;
}

.index-text{
  display: flex;
  align-items: flex-end;
}

.circle-oval{
  display: inline-table;
  /*width: 130px;*/
  min-width: 100px;
  width: auto;
  /* height: 35px; */
  border: solid 1px;
  border-radius: 80%;
  text-align: center;
  /* padding: 5px; */
}

.circle-oval-2{
  display: inline-table;
  /*width: 130px;*/
  min-width: 130px;
  padding: 10px;
  /* height: 35px; */
  border: solid 1px;
  border-radius: 80%;
  text-align: center;
  /* padding: 5px; */
}

.circle-oval-3{
  display: inline-table;
  /*width: 130px;*/
  min-width: 130px;
  padding: 10px;
  /* height: 35px; */
  border: solid 1px;
  border-radius: 80%;
  text-align: center;
  /* padding: 5px; */
}

.circle-oval-3:hover{
  border: solid 2px;
}


.leaflet-control-layers-toggle {
  background-image: url('../images/filter2.svg')!important;
  /*width: 36px;*/
  height: 36px;
}

.leaflet-container{
  color: #1d1d1d!important;
  /*font-weight: 100!important;*/
  text-transform: uppercase;
}

a.leaflet-control-zoom-in, a.leaflet-control-zoom-out{
  color: #fff!important;
}

.leaflet-control-zoom-in:hover, .leaflet-control-zoom-out:hover{
  color: #1d1d1d!important;
}

.leaflet-bar a{
  background-color: #1d1d1d!important;
}

.leaflet-bar a:hover{
  background-color: #ffffff!important;
}

.leaflet-popup-content{
font-weight: 500;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip{
  background-color: #1d1d1d!important;
  font-weight: 300;
  /*text-transform: uppercase;*/
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip{
  color:#fff!important;
}

.leaflet-bottom{
  display: none!important;
}

.leaflet-right{
  margin-right: 15px;
}

.leaflet-left{
  margin-left: 15px;
}

.leaflet-top{
  margin-top: 15px;
}

.leaflet-control-layers-overlays{
  font-size: 15px;
  padding: 10px;
}

.leaflet-control-layers-overlays label {
  font-style: normal;
  display: block;
}

.leaflet-control-layers-overlays label span {
  font-weight: 300;
}

label{
  margin-bottom: 0px!important;
}

.leaflet-control-layers-selector {
  margin-top: 5px!important;
  position: relative;
  top: 1px;
}

input[type=checkbox] {
	-moz-appearance:none;
	-webkit-appearance:none;
	-o-appearance:none;
	outline: none;
	content: none;	
  margin-top: 5px!important;
}

input[type=checkbox]:before {
    content: "\f00c";
    font-size: 12.5px;
    color: transparent !important;
    display: block;
    width: 15px;
    height: 15px;
    border: 2px solid black;
    margin-right: 7px;
}

input[type=checkbox]:checked:before {

	background-color: black !important;
  border: 2px solid black;
}

.size-full{
  padding:25px;
  font-weight: 100;
  word-spacing: -2px;
  text-align: justify;
  white-space: normal;
  line-height: 1.2;
  text-transform: none;
}


@media (min-width:1600px) {
.size-full{
  max-width: 90%;
  text-transform: none;
}
}

@media (min-width:1000px) {
  .leaflet-control-layers-overlays label span {
    font-weight: 300;
    font-size: 16px!important;
  }

  .oval{
    width: 130px;
  }

  .body-page{
    grid-gap: 2vw;
  }

  .size-full{
    font-weight: 100;
    text-transform: none;
  }

  .position-bottom{
    position: relative;
    bottom: 0;
  }

  }

  span{
    line-height: 1.2;
  }

  @media (max-width:1000px) {
    .text-align-left{
      text-align: left;
    }

    .leaflet-right{
      margin-right: 15px;
    }
    
    .leaflet-left{
      margin-left: 15px;
    }
    
    .leaflet-top{
      margin-top: 15px;
    }

    .oval{
      border-radius: 60%;
      width: 90px;
    }

    .border-map{
      padding-bottom: 30px;
    }

    .table th {
      font-weight: 300;
      text-transform: uppercase;
  }

    div.position-bottom{
      position: relative;
      padding-right: 0px;
      bottom: 0;
    }
    }

    .table th:hover {
      background-color: #c77945;
    }

/*ROTA*/

.position-bottom{
  padding-top: 15px;
  padding-right: 15px;
  bottom: 0;
}

.mapa_content{
  padding: 0 25px;
}

.table td, .table th{
  padding-top: 0.25rem;
  padding-bottom: 0.75rem;
  padding-left: 5px;
  padding-right: 5px;
  border-top: 1px solid #1d1d1d;
}

.icon-container:hover{
  cursor: pointer;
}

th:hover .icon-rotate{
  display: inline-block;
  opacity: 1;
  transition: all 0.6s ease;
  transform: rotate(0deg);
}

.icon-rotate{
  display: inline-block;
  opacity: 0;
  margin-bottom: 0px;
  transform: rotate(-40deg);
}

.icon-container{
  margin: 0 auto;
  display: inline-block;
  justify-content: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	align-items: center;
}


.table th{
  font-weight: 300;
  text-transform: uppercase;
}

.table{
  table-layout: fixed;
  width:90%;
}

.title-map{
  padding-top: 15px;
  padding-bottom: 25px;
  text-align: left!important;
}
.animate-fadeIn {
  border: 2px solid #1d1d1d;
  max-width: 94%;
  width: 0%;
  animation: grow 1.5s cubic-bezier(0.730, 0.000, 0.080, 0.845) forwards 1;
}

@keyframes grow {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.grey:hover{
  filter: none;
  /*cursor: ew-resize;*/
}

.opacity-0{
  border: none;
  width: 100%;
}


@media (min-width:1000px) {

.img-gallery{
  object-fit: cover;
  width: auto;
  height: auto;
  max-height: 70vh;
  max-width: 100vw;
}

.image-dif{
  width: 100%;
}
}

a{
  cursor: pointer!important;
}

.barra{
  /*padding: 8px;*/
  align-self: center;
  cursor: default;
}

.info-center{
  align-self: center;
}

a:hover{
  text-decoration: none;
  cursor: pointer!important;
}

.img-fluid-dif{
  object-fit: cover;
    height: 80vh;
    width: 100%;
}

.line-bottom-w{
  border-top: 2px solid #1d1d1d;
}

.with-margin-dif{
  padding: 12.5px 25px;
}

@media (max-width:1000px) {
  .pad-left{
    display:none
  }

  .change{
    display: flex;
    flex-direction: column;
  }

  .mapa_grid{
    margin-top: 50px!important;
  }

  #mapa { order: 1; margin-top: 0px; }
  #slideimage { order: 2; padding-bottom: 50px; }

  .table{
    width: 100%;
  }

  .animate-fadeIn {
    max-width: 100%;
  }

  .two-columns{
    column-count: 1;
    column-gap: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .body-content__rollig-text {
    white-space: nowrap;
    font-family: "Ogg";
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  .border-bottom{
    padding-left: 15px;
    border-bottom: 2px solid #fff;
  }

  .img-fluid-dif{
    z-index: 6;
    position: relative;
    height: auto;
    width: 100%;
    height: auto;
    min-height: 52vh;
    padding: 0px;
  }

  .no-wrap{
    display: flex;
    flex-wrap: wrap-reverse;
  }
  }


@media (min-width:1000px) {
  .section-content-header{
    height: 62.5px;
  }

  .pad{
    padding-right: 50px;
  }

.fullheight {
    min-height: 0!important;
    height: 100vh;
  }

.fullwidth-gallery {
  display: flex ;
  max-width: 100vw;
  width: 100vw;
  padding-left: 25px;
  padding-right: 25px;
} 

.fullwidth-gallery-cell{
  display: table-cell ;
  max-width: 100vw;
  width: 100vw;
  padding-left: 100px;
  padding-right: 25px;
}

.pad-left{
  padding-left: 20px!important;
}

img.dif-img{
  height: 95vh;
}

.space-info-column-two{
  height: 140px;
}

}

@media (max-width:1000px) {
  .circle-oval-2{
    max-width: 180px;
    min-width: 150px;
  }

  .circle-oval-3{
    max-width: 180px;
    min-width: 150px;
    margin-top: 50px;
  }

  .circle-oval-3:hover{

    border: 2px solid #1d1d1d;
  }

  .fullwidth-gallery {
    padding-left: 25px;
    padding-right: 25px;
  } 
  
  .dif-img{
    height: auto;
  }

  }


@media (max-width: 1000px) {
  .section--horizontal {
    width: 100vw;
    height: auto!important;
  }

  .fullwidth-gallery-cell{
    padding: 0 25px;
  }

  .margin-clear{
    margin-top: 50px;
    margin-bottom: 25px!important;
  }


  .margin-info{
    padding-top: 25px;
  }

  .hiper-info-home{
    margin-bottom: 0;
    cursor:pointer;
    padding-right: 0px!important;
  }

  .space-info{
    height: 2vh;
  }

  .space-info-column{
    height: 2vh;
  }

  .space-info-column-two{
    height: 5vh;
  }

  .hiper-info{
    margin-bottom: 0;
    padding-left: 0px!important;
  }

  .margins-lg{
    padding-left: 30px;
  }
  
  figure.figure{
    margin-bottom: 0px;
  }

  .section--horizontal .section__inner {
    width: 100%!important;
    height: auto!important;
    top: 0;
    left: 0;
    position: relative!important;
  }
  .section__inner{
    display: flex;
    flex-direction: column;
    transform: translateX(0)!important;
  }

  div.section__inner{
    transform: translate(0,0)!important;
    overflow-x: hidden;
  }
  .section--isFixed{
    transform: translateX(0)!important;
  }
  .section--isScrolled{
    transform: translateX(0)!important;
  }

  .parallax-img{
    position: relative;
  }

  .products{
    display: flex;
    flex-direction: column;
  }
  .section--isScrolled{
    display: none;
  }

  .section--horizontal{
    display: block;
  }

  .products__product{
    display: block!important;
    flex-basis: 0!important;
  }

  .section--isScrolled .section__inner{
    transform: translateX(0)!important;
    bottom: 0!important;
    top: 0!important;
  }

  .sliderhorizontal {
    width: 100vw!important;
    height: auto;
}

.img-fluid {
  object-fit: cover;
  min-height: 40vh;
  max-height: 60vh;
  width: 100%;
}

.img-fluid-dif{
  object-fit: cover;
  height: auto;
}

.image-dif{
  width: 100%;
}

.products__product{
  padding-top: 25px;
}
}

.section {
  min-height: 100vh;
  position: relative;
}

.section--horizontal {
  height: 300vw;
}
.section--horizontal .section__inner {
  width: auto;
  height: 100vh;
  position: absolute;
  top: -25px;
  left: 0;
}

.section--isFixed .section__inner {
  position: fixed;
}

.section--isScrolled .section__inner {
  bottom: 0;
  top: auto;
}

@media (min-width: 1000px) {

  .spacer{
    height: 100px;
  }

  p.legenda-image{
    padding-left: 25px;
  }

  .products__product {
    flex-basis: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 140px;
    padding-bottom: 0px!important;
    height: 100vh;
  }
}


.products {
  display: flex;
  /*max-height: 100vh;*/
}

.sliderhorizontal{
  width: auto;
}

@media (max-width: 1000px) {

  .section__inner{
    margin: 0px;
  }

  .section{
    padding-top: 0px;
  }

}

.space{
  height: 100px;
}

/**/
.line {
  width: 80%;
  height: 70px;
  position: relative;
  overflow: hidden;
}
.line span {
  position: absolute;
  line-height: 4.2rem;
}

/*pointer*/
.circle {
  z-index: 3000;
	position: absolute;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color:#ffbf1f;
	opacity: 0;
}


.circlemap {
  z-index: 3000;
	position: absolute;
	pointer-events:none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background: #ffbf1f;
	letter-spacing: 0.05em;
	color: #fff;
	transform: translate( -50%, -50%);
	transition-duration: 100ms; 
	transition-timing-function: ease-out;
	opacity: 0;
}


.customcursor{
  z-index: 30000;
	position: absolute;
	width: 20px;
	height: 20px;
	border-radius: 50%;
  pointer-events: none;
  transform: translate( -50%, -50%);
	background-color: #ffbf1f;
  opacity: 0;
}

@media (hover: none) {
  a:hover { color: inherit; }
}

/*caption mobile*/
@media (max-width: 1000px) {
  .mobile-caption {
    text-align: center;
    display: block;
    visibility: visible;
    margin: 0;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 300!important;
    text-transform: uppercase;
    padding-bottom: 12.5px;
    padding-top: 12.5px;
    border-bottom: 2px solid #1d1d1d;
  }


  div.line-border{
        border-left: 2px solid red;
        position: absolute;
  }

  .top-line{
    padding-top: 10px;
    border-top: 2px solid #1d1d1d;
  }
}

@media (min-width: 1000px) {
  .only-mobile {
    display: none;
  }
  .mobile-caption {
    display: none;
    visibility: hidden;
  }
}

.container-text-i{
  padding: 16px 30px;
}

.no-break {
  word-spacing: -2px;
  text-align: justify;
  white-space: normal;
  line-height: 1.2;
  font-weight: 300;
}

@-webkit-keyframes blink{
0%{opacity: 0;}
100%{opacity: 1;}
}

@-moz-keyframes blink{
0%{opacity: 0;}
100%{opacity: 1;}
}

@keyframes blink{
0%{opacity: 0;}
100%{opacity: 1;}
}

.link_poema{
  word-break:break-word;
  line-height: 1;
  font-weight: 500;
  cursor: help;
}

.link_poema:hover{
  color: #fff;
}

.accordion-li li{
  list-style: none;
  padding: 10 15px 0;
}

.accordion-li li:hover{
  font-weight: 500;
  color: #1d1d1d;
}

.arrow::after {
  display: inline-block;
  padding-left: 8px;
  content: "➞";
  transition: transform 0.3s ease-out;
}

.arrow:hover::after {
  transform: rotate(-45deg);
}

.container-text-home{
  display: flex;
  flex-wrap: wrap;
  width: auto;
  font-weight: 100;
}

/*caption desk*/
@media (max-width: 1000px) {

  .no-break{
    text-align: left;
  }
  
  .title_nav a{
    font-weight: 300;
  }

  .container-text-home{
    display: flex;
    flex-wrap: wrap;
    width: auto;
    font-weight: 100;
  }
}

img.grey.figure-img.img-fluid.img-info.cover{
  margin-bottom: 0px!important;
}

.figure-img-slide:hover{
  opacity: 0.7;
}

.flickity-page-dots {
  bottom: -35px!important;
}

p.replace-text-caption{
  font-weight: 400;
  text-transform: none;
}

.section-pers{
  display: block;
  padding: 12.5px 0;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 300;
}

/* HOME INDEX */
.all{
  overflow: hidden;
}

.home-title{
  font-family: 'Ogg';
  font-weight: 300; font-style: normal;
  font-variant: titling-caps;
}

@media (max-width: 1000px) {
.all{
  overflow: auto;
}
.home-title{
  font-family: 'Ogg';
  font-weight: 300; 
  font-style: normal;
  font-variant: titling-caps;
  line-height: 75px;
}
}

.footer-index{
  text-align: center;
  padding: 10px 25px;
}

/* FOOTER */

@media (max-width:1000px) {
  .right-sidebar {
    border-top: 2px solid #0d0d0d;
    border-left: none;
  }

  .audio-player__cover {
    top: 0px!important;
}

  .audio-players > div {
    max-width: 100%;
    display: block!important;
    bottom: 0;
}

  .cell-poema {
    max-width: 100vw;
    width: auto;
    height: 100%;
    max-height: 100vh;
    overflow: visible;
}

  p.footer_text{
    font-size: 16px;
    text-align: center;
  }
}

p.footer_text{
  font-size: 16px;
  text-align: center;
}

/* BODY */
body {
  font-family: 'normalidad-text', sans-serif;
  background-color: #c77945;
}


/* ANIMATION */

img.grey{
  filter: grayscale();
}

a .into-page{
  transition: 500ms ease;
  opacity: 0;
}

.img-info:hover{
  filter: none;
}

.transition-fade-anim{
  animation: opacidadeT 0.8s;
}

@keyframes opacidadeT {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

html.is-animating .transition-fade {
  transition: 500ms ease;
  opacity: 0;
}

/* FLICKITY */

.main-carousel{
  overflow: hidden; 
  width: 100%; 
}

.carousel-cell{
  margin-right: 10px;
}

.carousel-cell img{
  display: block;
  width: 100%;
}

@media (min-width: 1000px){

  .carousel-cell{
    margin-right: 20px;
  }
}

@media (max-width: 1000px) {
.main-carousel{
padding-top: 10px;
}  

.carousel-cell img {
  display: flex;
  width: 100%;
}
.carousel-cell{
  margin-right: 20px;
}

.cover {
  object-fit: cover;
  width: 100vw;}
}

.cover {
  object-fit: cover;
  height: 100%;
  width: auto;
  /*max-width: 100vw;*/
}

.flickity-enabled .carousel-cell {
  margin-bottom: 0;
}


.carousel-cell-image img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.flickity-enabled .carousel-cell{
  height:50vh;
  max-height: 100vh;
}

/* SCROLL */

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  width: 0;
  background: transparent;
}

/* LOGOS INFO */
.img-logo{
  width: 100px;
  height: auto;
  margin-top: -20px;
}

.two-lists{
  flex-flow: column wrap;
  justify-content: flex-end;
  /*display: flex;*/
}

/* BACKGROUND COLOR CHANGE */

.body-one{
  background-color: #ffffff;
  /*background-image: url("https://www.transparenttextures.com/patterns/noisy.png"); NOISE BACK*/
  transition: 0.5s ease-in-out;
  color:#1d1d1d;  
 }

 .changeColor {
  background-color: #1d1d1d;
  color:#fff;
}

.changeColorA {
  color:#fff;
}

.frame{
  border: 2px solid #fff;
  padding: 10px;
}


.width-pointer{
  width: 32px;
  height: auto;
  animation: rotation 4s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
    transition-delay: 4s;
  }
  to {
    transform: rotate(359deg);
    transition-delay: 8s;
  }
}

/* CSS RESET */
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* ::after,::before{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* DEFAULT */
audio,
canvas,
img,
svg,
video {
  vertical-align: middle; }

a {
  text-decoration: none;
}

p{
  margin-bottom: 0px;
}

/* GRID-TWO ALTERNATE  LAYOUT MAPA - GALERIA IMAGES */

@media (max-width: 1000px) {
#alternate{
  flex-direction:column-reverse;
  margin-top: 5px;
}
.margin-clear{
  margin-top: 0px;
}
}

/* LEAFLET */ 

.leaflet-dragging{
  -webkit-user-drag: none;
}

.mapa-grid-teste{
  padding-top: 60px;
  height:100vh;
  width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
}

.mapa_grid{
  margin-top: 100px;
  margin-bottom: 50px;
}

.mapa-top{
height: 60px;
}

#mapaCoimbra{
  height:100%;
  width: 100%;
  min-width: 100%;
  min-width: 100%;
  z-index: 9;
}

@media (max-width: 1000px) {

  .space-mobile{
    height: 220px;
  }

  .title-head {
    margin-left: 20px;
    z-index: 13;
    width: 300px!important;
    padding: 0 10px!important;
    position: absolute;
    text-align: left;
}

.circle-oval-3 {
  max-width: 180px;
  min-width: 150px;
  margin-top: 20px;
}

.title-head-h {
  font-family: Ogg;
  font-size: 25!important;
  font-weight: 800;
  text-transform: none;
}

  .mapa-grid-teste{
    padding-top: 45px;
  }

  #mapaCoimbra{
    height:60vh;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
  }
}

.leaflet-routing-container-hide{
  display: none;
}

/* ??????? */

.listOthers{
  padding-right: 2vw;
}

  @media (max-width: 796px) {

    .title-head {
      z-index: 13;
      width: 500px;
      padding: 0 10px;
      position: absolute;
  }

    .carousel-cell{
      padding-left: 15px;
    }

    .carousel-cell + .carousel-cell {
      padding-left: 0;
      padding-right: 15px;
    }

    .spacer-dot{
      height:20px!important;
    }

    .content-first {
      width: 100%;
      height: auto!important;
      padding: 0 0 0 30px;
      display: flex;
      flex-direction: column;
  }

    .main_home_page {
      flex-wrap: wrap;
      flex: 1 0;
      margin: 40px 0 0;
      display: flex;
      text-align: center;
      text-transform: uppercase;
  }

    .accordion-li li {
      padding: 5px 7.5px 0;
      font-size: 14px;
      list-style: none;
  }

  .set > a {
    padding: 5px 7.5px!important;
    font-size: 14px;
}

  .img-logo{
    width: 65px;
    height: auto;
  }
  
    .logos {
      padding-top: 0;
    }

    .section-content-header {
      padding: 12.5px 15px !important;
  }

    .menu-list a, .rotas {
      display: block;
      font-size: 1.25rem;
      transition: .5s transform;
      text-transform: capitalize;
    }

    .section {
      display: block; } }




      .accordion-container{
        position: relative;
        max-width: 90%;
        height: auto;
      }
      .accordion-container > h2{
        text-align: center;
        padding-bottom: 5px;
        margin-bottom: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid #ddd;
      }
      .set{
        position: relative;
        width: 100%;
        height: auto;
      }
      .set > a{
        text-transform: uppercase;
        display: block;
        padding: 10px 15px;
        text-decoration: none;
        color: #1d1d1d;
        font-weight: 500;
        border-bottom: 1px solid #1d1d1d;
        -webkit-transition:all 0.2s linear;
        -moz-transition:all 0.2s linear;
        transition:all 0.2s linear;
      }
      .set > a i{
        float: right;
        margin-top: 5px;
      }
      .set > a.active{
        background-color:#1d1d1d;
        color: #c77945;
      }
      .content{
        border-bottom: 1px solid #1d1d1d;
        display:none;
      }
      .content p{
        padding: 10px 15px;
        margin: 0;
        color: #1d1d1d;
      }    
      
      
      /*INDEX*/

      .content-first {
        width: 100%;
        height: 100vh;
        padding: 0 0 0 30px;
        display: flex;
    }
  
      .column {
        align-self: center;
        padding-top: 5vw;
      }

      .video-content{
        height: 100%;
      }

      .w-pad{
        margin:0!important;
      }

      .video-container {
        height: 50vh;
        max-height: 100%;
        position: relative;
        overflow: hidden;
      }

      @media (min-width: 600px) {
        .video-container {
          max-height: 100%;
          height: 100vh;
      }
      }
      
      video {
        object-fit: cover;
        position: absolute;
        height: 95%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 60px 0 0 60px;
      }

    .layer--overlay{
        background: #c77945;
        border-radius: 60px 0 0 60px;
        z-index: 10;
        width:100%;
        height:95%;
        position:absolute;
        top:0;
        left:0;
        right:0;
        opacity: 0.5;
      }

      .spacer-dot{
        height: 80px;
      }

      .figure-img{
        border-radius: 20px;
      }

      .caption{
        text-transform: none;
        font-weight: 400;
      }

      .last{
        height: 50px;
        margin-top: 100px;
      }

      .carousel-cell{
        padding-left: 30px;
      }

      .carousel-cell + .carousel-cell {
        padding-left: 0;
        padding-right: 30px;
      }

     .title-head {
        z-index: 13;
        padding: 0px 50px;
        position: absolute;
        width: 900px;
    }

    .title-head-h{
      font-weight: 800;
      font-size: 50px;
      font-family: 'Ogg';
    }