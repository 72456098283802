@import "https://use.typekit.net/cqt6qou.css";
@font-face {
  font-family: Ogg;
  src: url("index.72867287.woff2");
}

@font-face {
  src: url("https://use.typekit.net/cqt6qou.css");
}

@media only screen and (min-width: 0) {
  p {
    font-size: max(1.25rem, min(.875rem + 1.0417vw, 1.375rem));
  }

  span, th {
    font-size: max(1rem, min(1.9231vw - .1923rem, 1.25rem));
  }

  h1, .link_poema {
    font-size: max(1.5rem, min(1.5rem + 1.0417vw, 1.5rem));
  }

  .title-map {
    font-size: 29px;
  }

  .title_nav, h4 {
    font-size: max(.75rem, min(.625rem + 1.0417vw, .5rem));
  }

  .example-left {
    font-size: 46px;
  }
}

@media only screen and (min-width: 576px) {
  p {
    font-size: max(1.25rem, min(.875rem + 1.0417vw, 1.375rem));
  }

  span, th {
    font-size: max(1rem, min(1.9231vw - .1923rem, 1.25rem));
  }

  h1, .link_poema {
    font-size: max(1.5rem, min(1.5rem + 1.0417vw, 1.5rem));
  }

  .title-map {
    font-size: 29px;
  }

  .title_nav, h4 {
    font-size: max(.75rem, min(.625rem + 1.0417vw, 1rem));
  }

  .example-left {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) {
  p {
    font-size: max(1.375rem, min(.9464rem + .8929vw, 1.5rem));
  }

  span {
    font-size: max(1rem, min(1.9231vw - .1923rem, 1.25rem));
  }

  .table td, .table th {
    font-size: max(1.5rem, min(.625rem + .5vw, 1.5rem));
  }

  h1, .link_poema {
    font-size: max(2rem, min(1.3571rem + 1.3393vw, 2.1875rem));
  }

  .title-map {
    font-size: 29px;
  }

  .title_nav, h4 {
    font-size: max(1.125rem, min(.6964rem + .8929vw, 1rem));
  }

  .example-left {
    font-size: 70px;
  }
}

@media only screen and (min-width: 992px) {
  p {
    font-size: max(1.5rem, min(2.8846vw - .2885rem, 1.875rem));
  }

  span, th {
    font-size: max(1rem, min(.5rem + .5vw, 1.5rem));
  }

  h1, .link_poema {
    font-size: max(1.875rem, min(1.2788rem + .9615vw, 2rem));
  }

  .title-map {
    font-size: 37px;
  }

  .title_nav, h4 {
    font-size: max(1.125rem, min(.6964rem + .8929vw, 1rem));
  }

  .example-left {
    font-size: 90px;
  }
}

@media only screen and (min-width: 1200px) {
  p {
    font-size: max(1.875rem, min(1.125rem + 1vw, 2rem));
  }

  span, th {
    font-size: max(.5rem, min(.625rem + .5vw, 1.25rem));
  }

  h1, .link_poema {
    font-size: max(1.875rem, min(1.2788rem + .9615vw, 2rem));
  }

  .title-map {
    font-size: 37px;
  }

  .title_nav, h4 {
    font-size: max(1.05rem, min(.6964rem + .8929vw, 1rem));
  }

  .example-left {
    font-size: 90px;
  }
}

@media only screen and (min-width: 1600px) {
  h1, .link_poema {
    font-size: max(2rem, min(10vw - 5.5rem, 3.25rem));
  }

  .title-map {
    font-size: 37px;
  }

  .title_nav, h4 {
    font-size: max(1.125rem, min(.6964rem + .8929vw, 1.25rem));
  }
}

.bg-active {
  background-color: #fff;
  transition: all .5s ease-in-out;
}

@media (max-width: 1000px) {
  .content-first {
    width: 100%;
    flex-direction: column;
    padding: 0 0 0 30px;
    display: flex;
    height: auto !important;
  }

  .bg-active {
    background-color: #fff;
  }
}

.title_nav.light {
  font-weight: 300;
}

.legenda-slide {
  font-weight: 100 !important;
}

.background {
  color: #1d1d1d;
  background-color: #c6c4bd;
}

.border {
  border-radius: 16px;
  border: 2px solid #1d1d1d !important;
}

hr {
  margin-top: 0 !important;
}

html, body {
  height: 100%;
  width: 100%;
  color: #1d1d1d;
  margin: 0;
  padding: 0;
}

#preloader {
  z-index: 2147483647;
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.display-none {
  display: none;
}

.cell-poema {
  max-width: 100vw;
  width: 33.3vw;
  height: 100%;
  max-height: 100vh;
  overflow: visible;
}

.figure {
  width: 100vw;
  padding: 0 25px;
}

.image-galeria {
  width: 100vw;
  height: 80vh;
  object-fit: cover;
  border: 2px solid red;
}

.poema_image img {
  width: 100vw;
  height: 100%;
  display: block;
}

.poema_image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100vw;
}

.gridcontainer {
  height: 100%;
  max-height: 100%;
  justify-content: left;
  display: grid;
}

.text-left {
  padding-left: 0;
}

.text-right {
  padding-right: 0;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  width: 100%;
  min-height: 1px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.row_default {
  color: #1d1d1d;
  grid-template-columns: 1fr .2fr;
}

.col_default {
  color: #1d1d1d;
  grid-template-columns: 2fr 1fr;
}

p.item_main {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 1000px) {
  .section-content-header {
    padding: 12.5px 15px !important;
  }

  .gridcontainer {
    height: 100%;
    justify-content: left;
    display: block;
  }

  .no-mobile {
    display: none;
  }
}

#menu-bg {
  width: 100vw;
  height: 100vh;
  z-index: 12;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}

svg {
  fill: #c77945;
}

#menu-toggler {
  z-index: 500;
}

#menu-bg img {
  width: 100%;
}

#menu-contain {
  display: none;
}

#menu-contain.open {
  z-index: 12;
  min-height: 100vh;
  align-items: stretch;
  padding: 2vw;
  display: flex;
}

@media (max-width: 768px) {
  #menu-contain.open {
    flex-direction: column;
  }

  .accordion-container {
    height: auto;
    position: relative;
    max-width: 100% !important;
  }

  .hide-map, .hide-mobile {
    display: none;
  }

  #menu-contain.open {
    z-index: 12;
    min-height: 100vh;
    justify-content: flex-end;
    align-items: stretch;
    padding: 2vw;
    display: flex;
  }

  .itemsMenu, .itemsLogos {
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    padding: 1vw;
    display: flex;
    flex: 0 !important;
  }

  .row {
    justify-content: space-between;
  }
}

@media (max-width: 1000px) {
  .accordion-container {
    height: auto;
    position: relative;
    max-width: 100% !important;
  }

  #menu-contain.open {
    flex-direction: column;
  }

  .hide-map, .hide-mobile {
    display: none;
  }

  .row {
    justify-content: space-between;
  }

  #menu-contain.open {
    z-index: 12;
    min-height: 100vh;
    justify-content: flex-end;
    align-items: stretch;
    padding: 2vw;
    display: flex;
  }

  .itemsMenu, .itemsLogos {
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    padding: 1vw;
    display: flex;
    flex: 0 !important;
  }
}

.itemsMenu, .itemsLogos {
  flex-direction: column;
  flex: 1 0 50%;
  justify-content: flex-end;
  align-items: stretch;
  padding: 1vw;
  display: flex;
}

.itemsLogos {
  flex-direction: column;
  justify-content: flex-end;
}

.menu-list li {
  overflow: hidden;
}

.menu-list a, .rotas {
  text-transform: capitalize;
  font-size: 40px;
  transition: transform .5s;
  display: block;
}

.menu-list a:hover {
  text-decoration: none;
  transform: translateY(-100%) !important;
}

.menu-list a:after {
  content: attr(title);
  position: absolute;
  top: 100%;
  left: 0;
}

@media (min-width: 1000px) {
  .rota-mobile {
    display: none;
  }

  .text-left-dif {
    text-align: left;
  }

  .content_home_audio {
    max-height: 100vh;
    height: 100%;
    flex: 1 0;
    margin: 0;
  }

  .right-sidebar {
    z-index: 2147483647;
    height: 100vh;
    background-color: #fff;
    border-left: 2px solid #0d0d0d;
    flex: 0 0 50%;
    margin: 0;
    display: none;
    position: absolute;
    top: 62.5px;
    right: 0;
  }

  .content_home {
    max-height: 100vh;
    height: auto;
    flex: 1 0;
    margin: 0;
  }

  .cell-poema {
    max-width: 100vw;
    width: 33.3vw;
    height: 100%;
    max-height: 100vh;
    overflow: visible;
  }
}

.logos {
  flex-flow: wrap;
  padding-top: 20px;
  display: flex;
}

.row {
  flex-wrap: wrap;
  display: flex;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (max-width: 1000px) {
  .main_home_page {
    margin: 50px 0 0;
  }

  .rota-mobile {
    padding: 25px;
    font-weight: 500;
    display: block;
  }

  article {
    overflow: hidden;
    height: 13vh !important;
    margin-top: 5px !important;
  }
}

.leaflet-top, .leaflet-bottom {
  z-index: 400 !important;
}

article {
  max-width: 100%;
  min-height: 15vh;
  margin-top: 25px;
  position: relative;
}

.example-left {
  white-space: nowrap;
  text-transform: uppercase;
  position: absolute;
}

th:hover {
  background-color: #fff;
}

.example-left {
  white-space: nowrap;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
  color: #0000;
  font-family: Ogg;
}

.section {
  overflow: hidden !important;
}

.page-wrap {
  max-width: 100%;
  min-height: 100%;
  flex-direction: column;
  margin: 0;
  display: flex;
  overflow-x: hidden !important;
}

.main_home {
  flex-wrap: wrap;
  flex: 1 0;
  margin: 60px 0 0;
  display: flex;
}

.slide_home {
  flex: 0 0;
  margin: 0;
}

.content_home {
  max-height: 100vh;
  height: 45vh;
  flex: 1 0;
  margin: 0;
}

.right-sidebar {
  height: 100%;
  border-left: 2px solid #0d0d0d;
  flex: 0 0 50%;
  margin: 0;
  display: none;
}

@media screen and (max-width: 1000px) {
  .spacer-dot {
    height: 20px !important;
  }

  .sidebar, .content_home {
    height: auto;
    flex: 1 0 100%;
    align-content: space-around;
    margin: 0;
  }

  .content {
    order: -1;
  }
}

.show {
  display: block;
}

.scrollTop {
  transition: all .4s ease-in-out;
}

.grid-two {
  max-width: 100%;
  grid-template-rows: 1fr auto;
  grid-template-areas: "header"
                       "content"
                       "slideimage"
                       "mapa"
                       "footer";
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
}

.main_home_page {
  flex-wrap: wrap;
  flex: 1 0;
  margin: 90px 0 0;
  display: flex;
}

.mapa_grid {
  flex-wrap: wrap;
  flex: 1 0;
  margin: 25px 0;
  display: flex;
}

.slideimage {
  flex: 0 0;
  margin: 0;
}

.hide-map {
  opacity: 0 !important;
  display: none !important;
}

.fixed-position {
  position: fixed !important;
  overflow: hidden !important;
}

.shadow {
  z-index: 10;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  background: #fffc;
  box-shadow: 1px 5px 8px 5px #00473708;
}

.section-content-header {
  width: 100%;
  height: auto;
  text-transform: uppercase;
  z-index: 14;
  padding: 15px 30px;
  font-weight: 500;
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sticky {
  width: 100%;
  position: fixed;
  top: 0;
}

.sticky + .section-content-header {
  padding-top: 60px;
}

.title_nav {
  font-weight: 500;
}

.title_nav_light {
  font-weight: 100;
}

a:-webkit-any-link, a {
  color: #1d1d1d;
  cursor: pointer;
}

.links_pdf {
  text-align: left;
  padding: 25px;
}

.no-padding {
  padding-top: 0 !important;
}

.index-text {
  align-items: flex-end;
  display: flex;
}

.circle-oval {
  min-width: 100px;
  width: auto;
  text-align: center;
  border: 1px solid;
  border-radius: 80%;
  display: inline-table;
}

.circle-oval-2, .circle-oval-3 {
  min-width: 130px;
  text-align: center;
  border: 1px solid;
  border-radius: 80%;
  padding: 10px;
  display: inline-table;
}

.circle-oval-3:hover {
  border: 2px solid;
}

.leaflet-control-layers-toggle {
  height: 36px;
  background-image: url("filter2.a5edcab0.svg") !important;
}

.leaflet-container {
  text-transform: uppercase;
  color: #1d1d1d !important;
}

a.leaflet-control-zoom-in, a.leaflet-control-zoom-out {
  color: #fff !important;
}

.leaflet-control-zoom-in:hover, .leaflet-control-zoom-out:hover {
  color: #1d1d1d !important;
}

.leaflet-bar a {
  background-color: #1d1d1d !important;
}

.leaflet-bar a:hover {
  background-color: #fff !important;
}

.leaflet-popup-content {
  font-weight: 500;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  font-weight: 300;
  color: #fff !important;
  background-color: #1d1d1d !important;
}

.leaflet-bottom {
  display: none !important;
}

.leaflet-right {
  margin-right: 15px;
}

.leaflet-left {
  margin-left: 15px;
}

.leaflet-top {
  margin-top: 15px;
}

.leaflet-control-layers-overlays {
  padding: 10px;
  font-size: 15px;
}

.leaflet-control-layers-overlays label {
  font-style: normal;
  display: block;
}

.leaflet-control-layers-overlays label span {
  font-weight: 300;
}

label {
  margin-bottom: 0 !important;
}

.leaflet-control-layers-selector {
  position: relative;
  top: 1px;
  margin-top: 5px !important;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  content: none;
  outline: none;
  margin-top: 5px !important;
}

input[type="checkbox"]:before {
  content: "";
  width: 15px;
  height: 15px;
  border: 2px solid #000;
  margin-right: 7px;
  font-size: 12.5px;
  display: block;
  color: #0000 !important;
}

input[type="checkbox"]:checked:before {
  border: 2px solid #000;
  background-color: #000 !important;
}

.size-full {
  word-spacing: -2px;
  text-align: justify;
  white-space: normal;
  text-transform: none;
  padding: 25px;
  font-weight: 100;
  line-height: 1.2;
}

@media (min-width: 1600px) {
  .size-full {
    max-width: 90%;
    text-transform: none;
  }
}

@media (min-width: 1000px) {
  .leaflet-control-layers-overlays label span {
    font-weight: 300;
    font-size: 16px !important;
  }

  .oval {
    width: 130px;
  }

  .body-page {
    grid-gap: 2vw;
  }

  .size-full {
    text-transform: none;
    font-weight: 100;
  }

  .position-bottom {
    position: relative;
    bottom: 0;
  }
}

span {
  line-height: 1.2;
}

@media (max-width: 1000px) {
  .text-align-left {
    text-align: left;
  }

  .leaflet-right {
    margin-right: 15px;
  }

  .leaflet-left {
    margin-left: 15px;
  }

  .leaflet-top {
    margin-top: 15px;
  }

  .oval {
    width: 90px;
    border-radius: 60%;
  }

  .border-map {
    padding-bottom: 30px;
  }

  .table th {
    text-transform: uppercase;
    font-weight: 300;
  }

  div.position-bottom {
    padding-right: 0;
    position: relative;
    bottom: 0;
  }
}

.table th:hover {
  background-color: #c77945;
}

.position-bottom {
  padding-top: 15px;
  padding-right: 15px;
  bottom: 0;
}

.mapa_content {
  padding: 0 25px;
}

.table td, .table th {
  border-top: 1px solid #1d1d1d;
  padding: .25rem 5px .75rem;
}

.icon-container:hover {
  cursor: pointer;
}

th:hover .icon-rotate {
  opacity: 1;
  transition: all .6s;
  display: inline-block;
  transform: rotate(0deg);
}

.icon-rotate {
  opacity: 0;
  margin-bottom: 0;
  display: inline-block;
  transform: rotate(-40deg);
}

.icon-container {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: inline-block;
}

.table th {
  text-transform: uppercase;
  font-weight: 300;
}

.table {
  table-layout: fixed;
  width: 90%;
}

.title-map {
  padding-top: 15px;
  padding-bottom: 25px;
  text-align: left !important;
}

.animate-fadeIn {
  max-width: 94%;
  width: 0%;
  border: 2px solid #1d1d1d;
  animation: grow 1.5s cubic-bezier(.73, 0, .08, .845) forwards;
}

@keyframes grow {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.grey:hover {
  filter: none;
}

.opacity-0 {
  width: 100%;
  border: none;
}

@media (min-width: 1000px) {
  .img-gallery {
    object-fit: cover;
    width: auto;
    height: auto;
    max-height: 70vh;
    max-width: 100vw;
  }

  .image-dif {
    width: 100%;
  }
}

a {
  cursor: pointer !important;
}

.barra {
  cursor: default;
  align-self: center;
}

.info-center {
  align-self: center;
}

a:hover {
  text-decoration: none;
  cursor: pointer !important;
}

.img-fluid-dif {
  object-fit: cover;
  height: 80vh;
  width: 100%;
}

.line-bottom-w {
  border-top: 2px solid #1d1d1d;
}

.with-margin-dif {
  padding: 12.5px 25px;
}

@media (max-width: 1000px) {
  .pad-left {
    display: none;
  }

  .change {
    flex-direction: column;
    display: flex;
  }

  .mapa_grid {
    margin-top: 50px !important;
  }

  #mapa {
    order: 1;
    margin-top: 0;
  }

  #slideimage {
    order: 2;
    padding-bottom: 50px;
  }

  .table {
    width: 100%;
  }

  .animate-fadeIn {
    max-width: 100%;
  }

  .two-columns {
    column-count: 1;
    column-gap: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .body-content__rollig-text {
    white-space: nowrap;
    padding-top: 2vh;
    padding-bottom: 2vh;
    font-family: Ogg;
  }

  .border-bottom {
    border-bottom: 2px solid #fff;
    padding-left: 15px;
  }

  .img-fluid-dif {
    z-index: 6;
    height: auto;
    width: 100%;
    height: auto;
    min-height: 52vh;
    padding: 0;
    position: relative;
  }

  .no-wrap {
    flex-wrap: wrap-reverse;
    display: flex;
  }
}

@media (min-width: 1000px) {
  .section-content-header {
    height: 62.5px;
  }

  .pad {
    padding-right: 50px;
  }

  .fullheight {
    height: 100vh;
    min-height: 0 !important;
  }

  .fullwidth-gallery {
    max-width: 100vw;
    width: 100vw;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
  }

  .fullwidth-gallery-cell {
    max-width: 100vw;
    width: 100vw;
    padding-left: 100px;
    padding-right: 25px;
    display: table-cell;
  }

  .pad-left {
    padding-left: 20px !important;
  }

  img.dif-img {
    height: 95vh;
  }

  .space-info-column-two {
    height: 140px;
  }
}

@media (max-width: 1000px) {
  .circle-oval-2 {
    max-width: 180px;
    min-width: 150px;
  }

  .circle-oval-3 {
    max-width: 180px;
    min-width: 150px;
    margin-top: 50px;
  }

  .circle-oval-3:hover {
    border: 2px solid #1d1d1d;
  }

  .fullwidth-gallery {
    padding-left: 25px;
    padding-right: 25px;
  }

  .dif-img {
    height: auto;
  }

  .section--horizontal {
    width: 100vw;
    height: auto !important;
  }

  .fullwidth-gallery-cell {
    padding: 0 25px;
  }

  .margin-clear {
    margin-top: 50px;
    margin-bottom: 25px !important;
  }

  .margin-info {
    padding-top: 25px;
  }

  .hiper-info-home {
    cursor: pointer;
    margin-bottom: 0;
    padding-right: 0 !important;
  }

  .space-info, .space-info-column {
    height: 2vh;
  }

  .space-info-column-two {
    height: 5vh;
  }

  .hiper-info {
    margin-bottom: 0;
    padding-left: 0 !important;
  }

  .margins-lg {
    padding-left: 30px;
  }

  figure.figure {
    margin-bottom: 0;
  }

  .section--horizontal .section__inner {
    top: 0;
    left: 0;
    width: 100% !important;
    height: auto !important;
    position: relative !important;
  }

  .section__inner {
    flex-direction: column;
    display: flex;
    transform: translateX(0) !important;
  }

  div.section__inner {
    overflow-x: hidden;
    transform: translate(0) !important;
  }

  .section--isFixed, .section--isScrolled {
    transform: translateX(0) !important;
  }

  .parallax-img {
    position: relative;
  }

  .products {
    flex-direction: column;
    display: flex;
  }

  .section--isScrolled {
    display: none;
  }

  .section--horizontal {
    display: block;
  }

  .products__product {
    flex-basis: 0 !important;
    display: block !important;
  }

  .section--isScrolled .section__inner {
    top: 0 !important;
    bottom: 0 !important;
    transform: translateX(0) !important;
  }

  .sliderhorizontal {
    height: auto;
    width: 100vw !important;
  }

  .img-fluid {
    object-fit: cover;
    min-height: 40vh;
    max-height: 60vh;
    width: 100%;
  }

  .img-fluid-dif {
    object-fit: cover;
    height: auto;
  }

  .image-dif {
    width: 100%;
  }

  .products__product {
    padding-top: 25px;
  }
}

.section {
  min-height: 100vh;
  position: relative;
}

.section--horizontal {
  height: 300vw;
}

.section--horizontal .section__inner {
  width: auto;
  height: 100vh;
  position: absolute;
  top: -25px;
  left: 0;
}

.section--isFixed .section__inner {
  position: fixed;
}

.section--isScrolled .section__inner {
  top: auto;
  bottom: 0;
}

@media (min-width: 1000px) {
  .spacer {
    height: 100px;
  }

  p.legenda-image {
    padding-left: 25px;
  }

  .products__product {
    height: 100vh;
    flex-basis: 100vw;
    justify-content: center;
    align-items: center;
    padding-top: 140px;
    display: flex;
    padding-bottom: 0 !important;
  }
}

.products {
  display: flex;
}

.sliderhorizontal {
  width: auto;
}

@media (max-width: 1000px) {
  .section__inner {
    margin: 0;
  }

  .section {
    padding-top: 0;
  }
}

.space {
  height: 100px;
}

.line {
  width: 80%;
  height: 70px;
  position: relative;
  overflow: hidden;
}

.line span {
  line-height: 4.2rem;
  position: absolute;
}

.circle {
  z-index: 3000;
  pointer-events: none;
  width: 80px;
  height: 80px;
  opacity: 0;
  background-color: #ffbf1f;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.circlemap {
  z-index: 3000;
  pointer-events: none;
  width: 80px;
  height: 80px;
  letter-spacing: .05em;
  color: #fff;
  opacity: 0;
  background: #ffbf1f;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition-duration: .1s;
  transition-timing-function: ease-out;
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
}

.customcursor {
  z-index: 30000;
  width: 20px;
  height: 20px;
  pointer-events: none;
  opacity: 0;
  background-color: #ffbf1f;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

@media (hover: none) {
  a:hover {
    color: inherit;
  }
}

@media (max-width: 1000px) {
  .mobile-caption {
    text-align: center;
    visibility: visible;
    text-transform: uppercase;
    border-bottom: 2px solid #1d1d1d;
    margin: 0;
    padding: 12.5px 30px;
    display: block;
    font-weight: 300 !important;
  }

  div.line-border {
    border-left: 2px solid red;
    position: absolute;
  }

  .top-line {
    border-top: 2px solid #1d1d1d;
    padding-top: 10px;
  }
}

@media (min-width: 1000px) {
  .only-mobile {
    display: none;
  }

  .mobile-caption {
    visibility: hidden;
    display: none;
  }
}

.container-text-i {
  padding: 16px 30px;
}

.no-break {
  word-spacing: -2px;
  text-align: justify;
  white-space: normal;
  font-weight: 300;
  line-height: 1.2;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.link_poema {
  word-break: break-word;
  cursor: help;
  font-weight: 500;
  line-height: 1;
}

.link_poema:hover {
  color: #fff;
}

.accordion-li li {
  padding: 10px 15px 0;
  list-style: none;
}

.accordion-li li:hover {
  color: #1d1d1d;
  font-weight: 500;
}

.arrow:after {
  content: "➞";
  padding-left: 8px;
  transition: transform .3s ease-out;
  display: inline-block;
}

.arrow:hover:after {
  transform: rotate(-45deg);
}

.container-text-home {
  width: auto;
  flex-wrap: wrap;
  font-weight: 100;
  display: flex;
}

@media (max-width: 1000px) {
  .no-break {
    text-align: left;
  }

  .title_nav a {
    font-weight: 300;
  }

  .container-text-home {
    width: auto;
    flex-wrap: wrap;
    font-weight: 100;
    display: flex;
  }
}

img.grey.figure-img.img-fluid.img-info.cover {
  margin-bottom: 0 !important;
}

.figure-img-slide:hover {
  opacity: .7;
}

.flickity-page-dots {
  bottom: -35px !important;
}

p.replace-text-caption {
  text-transform: none;
  font-weight: 400;
}

.section-pers {
  padding: 12.5px 25px;
  font-weight: 300;
  display: block;
}

.all {
  overflow: hidden;
}

.home-title {
  font-variant: titling-caps;
  font-family: Ogg;
  font-style: normal;
  font-weight: 300;
}

@media (max-width: 1000px) {
  .all {
    overflow: auto;
  }

  .home-title {
    font-variant: titling-caps;
    font-family: Ogg;
    font-style: normal;
    font-weight: 300;
    line-height: 75px;
  }
}

.footer-index {
  text-align: center;
  padding: 10px 25px;
}

@media (max-width: 1000px) {
  .right-sidebar {
    border-top: 2px solid #0d0d0d;
    border-left: none;
  }

  .audio-player__cover {
    top: 0 !important;
  }

  .audio-players > div {
    max-width: 100%;
    bottom: 0;
    display: block !important;
  }

  .cell-poema {
    max-width: 100vw;
    width: auto;
    height: 100%;
    max-height: 100vh;
    overflow: visible;
  }

  p.footer_text {
    text-align: center;
    font-size: 16px;
  }
}

p.footer_text {
  text-align: center;
  font-size: 16px;
}

body {
  background-color: #c77945;
  font-family: normalidad-text, sans-serif;
}

img.grey {
  filter: grayscale();
}

a .into-page {
  opacity: 0;
  transition: all .5s;
}

.img-info:hover {
  filter: none;
}

.transition-fade-anim {
  animation: opacidadeT .8s;
}

@keyframes opacidadeT {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

html.is-animating .transition-fade {
  opacity: 0;
  transition: all .5s;
}

.main-carousel {
  width: 100%;
  overflow: hidden;
}

.carousel-cell {
  margin-right: 10px;
}

.carousel-cell img {
  width: 100%;
  display: block;
}

@media (min-width: 1000px) {
  .carousel-cell {
    margin-right: 20px;
  }
}

@media (max-width: 1000px) {
  .main-carousel {
    padding-top: 10px;
  }

  .carousel-cell img {
    width: 100%;
    display: flex;
  }

  .carousel-cell {
    margin-right: 20px;
  }

  .cover {
    object-fit: cover;
    width: 100vw;
  }
}

.cover {
  object-fit: cover;
  height: 100%;
  width: auto;
}

.flickity-enabled .carousel-cell {
  margin-bottom: 0;
}

.carousel-cell-image img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.flickity-enabled .carousel-cell {
  height: 50vh;
  max-height: 100vh;
}

::-webkit-scrollbar {
  width: 0;
  background: none;
}

::-webkit-scrollbar-thumb {
  width: 0;
  background: none;
}

.img-logo {
  width: 100px;
  height: auto;
  margin-top: -20px;
}

.two-lists {
  flex-flow: column wrap;
  justify-content: flex-end;
}

.body-one {
  color: #1d1d1d;
  background-color: #fff;
  transition: all .5s ease-in-out;
}

.changeColor {
  color: #fff;
  background-color: #1d1d1d;
}

.changeColorA {
  color: #fff;
}

.frame {
  border: 2px solid #fff;
  padding: 10px;
}

.width-pointer {
  width: 32px;
  height: auto;
  animation: rotation 4s linear infinite;
}

@keyframes rotation {
  from {
    transition-delay: 4s;
    transform: rotate(0deg);
  }

  to {
    transition-delay: 8s;
    transform: rotate(359deg);
  }
}

*, * :after, :before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

audio, canvas, img, svg, video {
  vertical-align: middle;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0;
}

@media (max-width: 1000px) {
  #alternate {
    flex-direction: column-reverse;
    margin-top: 5px;
  }

  .margin-clear {
    margin-top: 0;
  }
}

.leaflet-dragging {
  -webkit-user-drag: none;
}

.mapa-grid-teste {
  height: 100vh;
  width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
  padding-top: 60px;
}

.mapa_grid {
  margin-top: 100px;
  margin-bottom: 50px;
}

.mapa-top {
  height: 60px;
}

#mapaCoimbra {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-width: 100%;
  z-index: 9;
}

@media (max-width: 1000px) {
  .space-mobile {
    height: 220px;
  }

  .title-head {
    z-index: 13;
    text-align: left;
    margin-left: 20px;
    position: absolute;
    width: 300px !important;
    padding: 0 10px !important;
  }

  .circle-oval-3 {
    max-width: 180px;
    min-width: 150px;
    margin-top: 20px;
  }

  .title-head-h {
    text-transform: none;
    font-family: Ogg;
    font-weight: 800;
    font-size: 25px !important;
  }

  .mapa-grid-teste {
    padding-top: 45px;
  }

  #mapaCoimbra {
    height: 60vh;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
  }
}

.leaflet-routing-container-hide {
  display: none;
}

.listOthers {
  padding-right: 2vw;
}

@media (max-width: 796px) {
  .title-head {
    z-index: 13;
    width: 500px;
    padding: 0 10px;
    position: absolute;
  }

  .carousel-cell {
    padding-left: 15px;
  }

  .carousel-cell + .carousel-cell {
    padding-left: 0;
    padding-right: 15px;
  }

  .spacer-dot {
    height: 20px !important;
  }

  .content-first {
    width: 100%;
    flex-direction: column;
    padding: 0 0 0 30px;
    display: flex;
    height: auto !important;
  }

  .main_home_page {
    text-align: center;
    text-transform: uppercase;
    flex-wrap: wrap;
    flex: 1 0;
    margin: 40px 0 0;
    display: flex;
  }

  .accordion-li li {
    padding: 5px 7.5px 0;
    font-size: 14px;
    list-style: none;
  }

  .set > a {
    font-size: 14px;
    padding: 5px 7.5px !important;
  }

  .img-logo {
    width: 65px;
    height: auto;
  }

  .logos {
    padding-top: 0;
  }

  .section-content-header {
    padding: 12.5px 15px !important;
  }

  .menu-list a, .rotas {
    text-transform: capitalize;
    font-size: 1.25rem;
    transition: transform .5s;
    display: block;
  }

  .section {
    display: block;
  }
}

.accordion-container {
  max-width: 90%;
  height: auto;
  position: relative;
}

.accordion-container > h2 {
  text-align: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.set {
  width: 100%;
  height: auto;
  position: relative;
}

.set > a {
  text-transform: uppercase;
  color: #1d1d1d;
  border-bottom: 1px solid #1d1d1d;
  padding: 10px 15px;
  font-weight: 500;
  text-decoration: none;
  transition: all .2s linear;
  display: block;
}

.set > a i {
  float: right;
  margin-top: 5px;
}

.set > a.active {
  color: #c77945;
  background-color: #1d1d1d;
}

.content {
  border-bottom: 1px solid #1d1d1d;
  display: none;
}

.content p {
  color: #1d1d1d;
  margin: 0;
  padding: 10px 15px;
}

.content-first {
  width: 100%;
  height: 100vh;
  padding: 0 0 0 30px;
  display: flex;
}

.column {
  align-self: center;
  padding-top: 5vw;
}

.video-content {
  height: 100%;
}

.w-pad {
  margin: 0 !important;
}

.video-container {
  height: 50vh;
  max-height: 100%;
  position: relative;
  overflow: hidden;
}

@media (min-width: 600px) {
  .video-container {
    max-height: 100%;
    height: 100vh;
  }
}

video {
  object-fit: cover;
  height: 95%;
  width: 100%;
  border-radius: 60px 0 0 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.layer--overlay {
  z-index: 10;
  width: 100%;
  height: 95%;
  opacity: .5;
  background: #c77945;
  border-radius: 60px 0 0 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.spacer-dot {
  height: 80px;
}

.figure-img {
  border-radius: 20px;
}

.caption {
  text-transform: none;
  font-weight: 400;
}

.last {
  height: 50px;
  margin-top: 100px;
}

.carousel-cell {
  padding-left: 30px;
}

.carousel-cell + .carousel-cell {
  padding-left: 0;
  padding-right: 30px;
}

.title-head {
  z-index: 13;
  width: 900px;
  padding: 0 50px;
  position: absolute;
}

.title-head-h {
  font-family: Ogg;
  font-size: 50px;
  font-weight: 800;
}

/*# sourceMappingURL=index.8e3b56c6.css.map */
